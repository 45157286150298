import React, { useState } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

// ========== Animations ==========
const float = keyframes`
 0% { box-shadow: 0 0 30px rgba(108, 71, 224, 0.5); }
  50% { box-shadow: 0 0 50px rgba(108, 71, 224, 0.8); }
  100% { box-shadow: 0 0 50px rgba(108, 71, 224, 0.5); }
`;
const subtlePulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.005); }
  100% { transform: scale(1); }
`;
const glow = keyframes`
  0% { box-shadow: 0 0 30px rgba(108, 71, 224, 0.5); }
  50% { box-shadow: 0 0 50px rgba(108, 71, 224, 0.8); }
  100% { box-shadow: 0 0 50px rgba(108, 71, 224, 0.5); }
`;

const shake = keyframes`
  0%, 100% { transform: translateX(0); }
  20%, 60% { transform: translateX(-1px); }
  40%, 80% { transform: translateX(1px); }
`;

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 5rem;
  background-color: #000;
  color: white;
  @media (max-width: 600px) {
  padding: 5rem;
  }

`;

const FloatingElements = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 0;

  div {
    position: absolute;
    border-radius: 50%;
    background: rgba(108, 71, 224, 0.1);
    filter: blur(30px);
    animation: ${float} 6s ease-in-out infinite;
    
    &:nth-of-type(1) {
      width: 200px;
      height: 200px;
      top: 20%;
      left: 10%;
      animation-delay: 0s;
    }
    
    &:nth-of-type(2) {
      width: 300px;
      height: 300px;
      bottom: 15%;
      right: 10%;
      animation-delay: 2s;
      animation-duration: 8s;
    }
    
    &:nth-of-type(3) {
      width: 150px;
      height: 150px;
      top: 60%;
      left: 30%;
      animation-delay: 1s;
      animation-duration: 7s;
    }
  }
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 3.5rem;
  justify-items: normal;
  background: rgba(20, 20, 20, 0.8);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(108, 71, 224, 0.3);
  position: relative;
  z-index: 1;
  animation: ${fadeIn} 0.8s ease-out, ${glow} 3s infinite;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
  @media (max-width: 600px) {
  max-width: 400px;
  }
`;

const Title = styled.h2`
  color: #6c47e0;
  margin-bottom: 2rem;
  font-size: 2.4rem;
  text-align: center;
  position: relative;
  text-shadow: 0 0 30px rgba(108, 71, 224, 0.5);
  
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 3px;
    background: #6c47e0;
    border-radius: 3px;
  }
`;

const InputGroup = styled.div`
  margin-bottom: 1.8rem;
  position: relative;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.8rem;
  font-size: 16px;
  transition: all 0.3s ease;
  text-align: left;
  margin-left: 23px;
  color: #ffffffb9;
  @media (max-width: 600px) {
    margin-left: 10px;
  }
`;

const Input = styled.input`
  width: 85%;
  padding: 15px 20px;
  background: rgba(30, 30, 30, 0.8);
  border: 1px solid #333;
  border-radius: 10px;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  transition: all 0.3s ease;
  
  &:focus {
    outline: none;
    border-color: #6c47e0;
    box-shadow: 0 0 0 3px rgba(108, 71, 224, 0.2);
    animation: ${shake} 0.4s ease;
  }
  
  &::placeholder {
    color: #555;
  }
`;

const TextArea = styled.textarea`
  width: 85%;
  padding: 15px 20px;
  background: rgba(30, 30, 30, 0.8);
  border: 1px solid #333;
  border-radius: 10px;
  color: white;
  font-size: 1rem;
  min-height: 150px;
  cursor: pointer;
  resize: vertical;
  transition: all 0.3s ease;
  
  &:focus {
    outline: none;
    border-color: #6c47e0;
    box-shadow: 0 0 0 3px rgba(108, 71, 224, 0.2);
    animation: ${shake} 0.4s ease;
  }
  
  &::placeholder {
    color: #555;
  }
`;

const SubmitButton = styled.button`
  width: 92%;
  padding: 16px;
  background: #6c47e0;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(108, 71, 224, 0.4);
  animation: ${glow} 4s infinite, ${subtlePulse} 3s infinite;
  
  &:hover {
    background: #5b3ac9;
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgba(108, 71, 224, 0.6);
    animation: ${glow} 2s infinite, ${subtlePulse} 1.5s infinite;
  }
  
  &:active {
    transform: translateY(1px);
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
  }
  
  &:focus:not(:active)::after {
    animation: ${float} 0.6s ease-out;
  }
`;

const SuccessMessage = styled.div`
  margin-top: 1.5rem;
  padding: 1.2rem;
  background: rgba(108, 71, 224, 0.15);
  border-left: 4px solid #6c47e0;
  border-radius: 8px;
  animation: ${fadeIn} 0.5s ease-out;
  text-align: center;
`;


const Contactus = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setSubmitted(true);
    setFormData({ name: '', email: '', message: '' });
    
    setTimeout(() => {
      setSubmitted(false);
    }, 5000);
  };

  return (
    <Wrapper>
      <FloatingElements>
        <div></div>
        <div></div>
        <div></div>
      </FloatingElements>
      
      <FormContainer>
        <Title>Contact Us</Title>
        <form onSubmit={handleSubmit}>
          <InputGroup>
            <Label htmlFor="name">Your Name</Label>
            <Input
              type="text"
              id="name"
              name="name"
              autoComplete='off'
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter your name"
              required
            />
          </InputGroup>
          
          <InputGroup>
            <Label htmlFor="email">Email Address</Label>
            <Input
              type="email"
              id="email"
              name="email"
              autoComplete='off'
              value={formData.email}
              onChange={handleChange}
              placeholder="your@email.com"
              required
            />
          </InputGroup>
          
          <InputGroup>
            <Label htmlFor="message">Your Message</Label>
            <TextArea
              id="message"
              name="message"
              autoComplete='off'
              value={formData.message}
              onChange={handleChange}
              placeholder="Write your message"
              required
            />
          </InputGroup>
          
          <SubmitButton type="submit">
            Send Message
          </SubmitButton>
        </form>
        
        {submitted && (
          <SuccessMessage>
            Thank you! Your message has been sent successfully.
          </SuccessMessage>
        )}
      </FormContainer>
    </Wrapper>
  );
};

export default Contactus;