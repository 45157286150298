import React from 'react'
import styled from '@emotion/styled';
import Ramdanofferhome from '../../Components/RamdanOffers_Page';
import ramdan from '../../../assets/Images/ramdan.svg'
import LockerMenu from '../../Components/LockerMenu';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import Summary from '../../Components/Sections/S7-Summary';
import ReviewSection from '../../Components/Sections/S5-1-Reviews';
import Faq from '../../Components/Sections/S6-Faq';
import End from '../../Components/Sections/S8-End';

const Maindiv = styled.div`
    width: 100%;
    min-height: 100vh;
    text-align: center;
    position: relative;
    overflow: hidden;

    &::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(${ramdan}) no-repeat center center/cover;
        z-index: -1;
    }
`;

const RamadanOffer = () => {
      useEffect(()=>{
        AOS.init()
      })
  return (
    <Maindiv>
      <LockerMenu/>
        <Ramdanofferhome/>
        <div style={{paddingBottom:'100px'}}>
        <ReviewSection/> 
        </div>
        <Faq/>
        <div style={{paddingBottom:'100px'}}>
        <End/>
        </div>
       <Summary/>
    </Maindiv>
  )
}

export default RamadanOffer