import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Typography, Grid, Box, Divider, Modal, Backdrop, Fade, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import PurpleButton from '../PurpleButton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 240px 0;
  text-align: center;
`;

const Headertext = styled(Typography)`
  font-size: 120px;
  color: #F9D9FF;
  font-weight: 300;
  max-width: 600px;
  line-height: 1.3;
`;

const Purchase = styled(Typography)`
  margin-top: 230px;
  font-size: 60px;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 50px;
`;

const PaymentModal = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #000;
  border-radius: 14px;
  padding: 20px;
  text-align: center;
  @media (max-width: 600px) {
 width: 100%;
 max-width: 300px;
  }
`;

const PaymentOption = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0;
  background-color: #1B1821;
  padding: 10px;
  color: #fff;
  border-radius: 10px;

`;

const Paymentbox = styled(Box)`
@media (max-width: 900px) {
margin-top: 40px;
  }
`


const RamadanOfferHome = () => {
  const [open, setOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('crypto');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handlePayClick = () => {
    if (paymentMethod === 'crypto') {
      window.open('https://app.shadownode.org/Buy_svpn', '_blank');
    } else {
      setOpen(false);
    }
  };
  

  return (
    <Wrapper>
      <Headertext fontFamily="Youngotf" fontSize={{xs:'83px',md:'90px',lg:'125px'}}>Ramadan Kareem Discount</Headertext>
      <Purchase fontFamily="PoppinsL">Purchase SVPN</Purchase>
          <Box display='flex' gap={3} sx={{ display: { xs: "block", md: "flex" }, gap: { md: 3 }}}>

          <Grid item xs={12} sm={12} md={12} lg={5.5} sx={{backgroundColor:"#100E13",borderRadius:"15px", }}>  
                <Box  sx={{width:"calc(100% - 40px)",display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center",gap:"10px",padding:"20px",}}>
                <Box  sx={{width:"calc(100% - 40px)",display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center",gap:"10px",padding:"20px"}}> 
                  <Typography textAlign="center" color='#835DFF' fontFamily=" PoppinsL;" fontSize={{xs:"20px",lg:"20px"}}>Monthly</Typography>  
                  <Typography variant="body1"  textAlign="center" fontFamily=" PoppinsM;" style={{color:'#ffffff', textDecoration: 'line-through',marginBottom:'-8px'}} fontSize={{xs:'30px',md:'30px'}}>5,457.32 SVPN</Typography>
                  <Typography  variant="body1"  textAlign="center" fontFamily=" PoppinsL;"  style={{color:'#707070', textDecoration: 'line-through'}} fontSize={{xs:'17px',md:'20px'}}>5 $</Typography>
                  <Box  sx={{width: '230px', background: '#000', borderRadius: '12px',color: '#fff',textAlign: 'center',border:'1px solid #835DFF',background: 'linear-gradient(180deg, #080709 52%, #835DFF 180%)',padding:'25px'}}>
                    <Typography color={'#ffffff'} fontFamily=" PoppinsM;"   fontSize={{xs:"20px",lg:"29px"}}>50% Off</Typography>
                  <Typography  variant="body1"   color="#835DFF"fontFamily=" PoppinsM;" fontSize={{xs:"20px",lg:"20px"}}  >Ramadan Discount</Typography>
                  <Divider sx={{ borderColor: '#835DFF', borderBottomWidth: 1, margin:'10px -16px'  }} ></Divider> 
                  <Typography  variant="body1"  color="#ffffff" fontFamily=" PoppinsM;" fontSize={{xs:'25px',md:'30px'}} >2,728.66 SVPN</Typography>
                  <Typography  variant="body1"   color="#ffffff" fontFamily=" PoppinsM;" fontSize={{xs:'20px',md:'20px'}} >2.5 $</Typography>
                  </Box>
                </Box> 
                </Box> 
                <Divider sx={{ borderColor: '#373141', borderBottomWidth: 1, margin:'20px -0px' ,marginTop:'-10px'  }} ></Divider>
                <PurpleButton onClick={handleOpen} href="" p="7px 100px" target="blank" sx={{fontSize:"17px !important",minWidth: "130px !important",borderRadius: "8px !important",marginBottom: "30px !important"}}>Buy now</PurpleButton> 
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={5.5} sx={{backgroundColor:"#100E13",borderRadius:"15px"}}>  
                <Paymentbox  sx={{ width:"calc(100% - 40px)",display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center",gap:"10px",padding:"20px"}}>
                <Box  sx={{width:"calc(100% - 40px)",display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center",gap:"10px",padding:"20px"}}> 
                  <Typography textAlign="center" color='#835DFF' fontFamily=" PoppinsL;" fontSize={{xs:"20px",lg:"20px"}}>Yearly</Typography>  
                  <Typography variant="body1"  textAlign="center" fontFamily=" PoppinsM;" style={{color:'#ffffff', textDecoration: 'line-through',marginBottom:'-8px'}} fontSize={{xs:'30px',md:'30px'}}>60,030.56 SVPN</Typography>
                  <Typography  variant="body1"  textAlign="center" fontFamily=" PoppinsL;"  style={{color:'#707070', textDecoration: 'line-through'}} fontSize={{xs:'17px',md:'20px'}} >55 $</Typography>
                  <Box  sx={{width: '230px', background: '#000', borderRadius: '12px',color: '#fff',textAlign: 'center',border:'1px solid #835DFF',background: 'linear-gradient(180deg, #080709 52%, #835DFF 180%)',padding:'25px'}}>
                    <Typography color={'#ffffff'} fontFamily=" PoppinsM;"   fontSize={{xs:"20px",lg:"29px"}}>50% Off</Typography>
                  <Typography  variant="body1"   color="#835DFF"fontFamily=" PoppinsM;" fontSize={{xs:"20px",lg:"20px"}}  >Ramadan Discount</Typography>
                  <Divider sx={{ borderColor: '#835DFF', borderBottomWidth: 1, margin:'10px -16px'  }} ></Divider> 
                  <Typography  variant="body1"  color="#ffffff" fontFamily=" PoppinsM;" fontSize={{xs:'25px',md:'30px'}} >30,015.28 SVPN</Typography>
                  <Typography  variant="body1"   color="#ffffff" fontFamily=" PoppinsM;" fontSize={{xs:'20px',md:'20px'}} >27.5 $</Typography>
                  </Box>
                </Box> 
                </Paymentbox> 
                <Divider sx={{ borderColor: '#373141', borderBottomWidth: 1, margin:'20px -0px' ,marginTop:'-10px'  }} ></Divider>
                <PurpleButton onClick={handleOpen} href="" p="7px 100px" target="blank" sx={{fontSize:"17px !important",minWidth: "130px !important",borderRadius: "8px !important",marginBottom: "30px !important"}}>Buy now</PurpleButton> 
              </Grid>
              </Box>

            <Modal open={open} onClose={handleClose} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
            <Fade in={open}>
              <PaymentModal>
                <Typography variant="h6" color="#ffffff" fontFamily="PoppinsM;">
                  Select Payment Method
                </Typography>
                <RadioGroup name="paymentMethod" value={paymentMethod} onChange={handlePaymentMethodChange}>
                  <PaymentOption>
                    <FormControlLabel  value="crypto" control={<Radio sx={{ color: '#835DFF', '&.Mui-checked': { color: '#835DFF' } }} />} label={<Typography fontFamily="PoppinsM;" color="#ffffff">Crypto</Typography>} />
                  </PaymentOption>
                  <PaymentOption>
                    <FormControlLabel  value="creditCard" control={<Radio sx={{ color: '#835DFF', '&.Mui-checked': { color: '#835DFF' } }} />} label={<Typography fontFamily="PoppinsM;" color="#ffffff">Credit Card</Typography>} />
                  </PaymentOption>
                </RadioGroup>
                <PurpleButton  onClick={handlePayClick} p="7px 100px" target="_blank" 
                  sx={{ fontSize: "17px !important", minWidth: "160px !important", borderRadius: "8px !important", marginBottom: "10px !important", marginTop: "25px !important", fontFamily: "PoppinsM !important" }} >
                  Pay
                </PurpleButton>
              </PaymentModal>
            </Fade>
          </Modal>
    </Wrapper>
  );
};

export default RamadanOfferHome;
