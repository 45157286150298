import { useState,useEffect} from 'react';
import styled from '@emotion/styled';
import LockerMenu from '../../Components/LockerMenu';
import Contactus from '../../Components/Contactus';
import TopRighttImg from '../../../assets/Images/newDesign/right.png'
import BottomleftImg from '../../../assets/Images/newDesign/left.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Summary from '../../Components/Sections/S7-Summary';

const Maindiv  = styled.div`
     width: 100%;
    min-height: 100vh;
    text-align: center;
    position: relative;
    overflow: hidden;
`  

const  TopRightimg = styled.img` 
     position: fixed;
    width: 40%;
    top: 0%;
    right: 0%; 
    opacity: 0.5;
    z-index: 1; 
    @media(max-width:599px){
       width: 80% !important; 
    }
` 
const  BottomLeftimg = styled.img`
    position: fixed;
    width: 40%;
    bottom: 0%;
    left: 0%;
    z-index: 1;   
    opacity: 0.5!important;
    @media(max-width:599px){
       width: 80% !important; 
    }
`
const ContactPage = () => {
        useEffect(()=>{
          AOS.init()
        })
  return (
    <Maindiv>
             <TopRightimg src={TopRighttImg} alt="topRightborderImg"  /> 
             <BottomLeftimg src={BottomleftImg} alt="BottomRightborderImg"  /> 
           <div id="stars"></div>
           <div id="stars2"></div>
     <LockerMenu/>
     <div style={{paddingTop:'100px'}}>
     <Contactus/>
     </div>
     <Summary/>
    </Maindiv>
  )
}

export default ContactPage